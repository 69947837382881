<template>
  <div>
    <b-overlay
      :show="upgradeOverlay"
    >
      <div>
        <b-card no-body>
          <b-card-header class="pb-50">
            <h5>Filters</h5>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="4" lg="4" xl="3" class="mb-md-0 mb-2">
                <label>Category</label>
                <b-form-select
                  @change="filterToolkits"
                  v-model="category"
                  :options="categoryOptions"
                  class="w-100"
                />
              </b-col>
              <b-col cols="12" md="5" lg="6" xl="2" class="mb-md-0 mb-2">
                <label>Search</label>
                <b-form-input
                  v-model="toolkitSearch"
                  type="text"
                  placeholder="Keyword"
                />
              </b-col>
              <b-col cols="12" md="3" lg="2" class="mb-md-0 mb-2" style="padding-top: 3px;">
                <label></label>
                <b-button class="bg-mhc-dark" block @click="filterToolkits">
                  <feather-icon
                    icon="FilterIcon"
                    class="mr-50"
                  />
                  Search 
                </b-button>
              </b-col>
              <b-col cols="12" lg="12" xl="5" class="mb-md-0 mb-2">
                <div class="letterFilter w-500 mx-auto mt-0 mt-md-2">
                  <span
                    class="text-uppercase"
                    v-for="(letter, index) in letters"
                    :key="index"
                    @click="setLetter(letter)"
                    v-bind:class="{
                      'green-text': letter.flag,
                      'text-underline': firstLetter == letter.letter,
                    }"
                    >{{ letter.letter }}</span
                  >
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
      
      <b-overlay :show="show" rounded="sm">
        <b-card v-if="recommendedToolkit">
          <div v-if="toolkits.toolkits.length > 0" class="toolkit-grid-container" :class="toolkits.toolkits.length == 1 ? 'one-item' : '' ">
            <new-toolkit-card
              v-for="toolkit in toolkits.toolkits"
              :key="toolkit.id"
              :toolkit="toolkit"
              :category="false"
            />
          </div>
          <div v-if="areToolkitsFound(toolkits) == false">
            No toolkits found
          </div>
        </b-card>
        <b-card v-else-if="questionnaire != null">
          <div v-for="(toolkitCategory, index) in toolkits" :key="index">
            <div v-if="toolkitCategory.toolkits.length > 0" class="toolkit-grid-container" :class="toolkitCategory.toolkits.length == 1 ? 'one-item' : '' ">
              <new-toolkit-card
                v-for="toolkit in toolkitCategory.toolkits"
                :key="toolkit.id"
                :toolkit="toolkit"
                :category="false"
              />
            </div>
          </div>
          <div v-if="areToolkitsFound(toolkits) == false">
            No toolkits found
          </div>
        </b-card>
        <b-card v-else>
          <div v-for="(toolkitCategory, index) in toolkits" :key="index">
            <div v-if="toolkitCategory.toolkits.length > 0" class="toolkit-grid-container" :class="toolkitCategory.toolkits.length == 1 ? 'one-item' : '' ">
              <new-toolkit-card
                v-for="toolkit in toolkitCategory.toolkits"
                :key="toolkit.id"
                :toolkit="toolkit"
                :category="false"
              />
            </div>
          </div>
          <div v-if="areToolkitsFound(toolkits) == false">
            No toolkits found
          </div>
        </b-card>
        
      </b-overlay>
      
      <b-card no-body>
        <b-pagination
        class="pt-1 pl-1"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="12"
          hide-goto-end-buttons
        />
      </b-card>

      <template #overlay>
        <div class="text-center">
          <b-card class="p-1" style="border-radius: 25px;">
            <h3 class="font-weight-normal">Upgrade for full access</h3>
            <p class="mt-1 font-weight-normal">Limited trial offer from £1</p>
            <b-button @click="tellMeMore" variant="primary" class="mt-1">Tell me more</b-button>
          </b-card>
        </div>
      </template>
    </b-overlay>

    <update-toolkit-reminder />

  </div>
</template>

<script>
import NewToolkitCard from "@/components/toolkit/NewToolkitCard.vue";
import UpdateToolkitReminder from "@/components/modals/UpdateToolkitReminder.vue"
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BFormSelect,
  BFormInput,
  BButton,
  BOverlay,
  BPagination
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import WhatDoesThisMean from "../../components/modals/WhatDoesThisMean.vue";
import EventBus from '@/main.js'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
    BFormInput,
    BButton,
    BOverlay,
    NewToolkitCard,
    UpdateToolkitReminder,
    BPagination,
    WhatDoesThisMean
  },

  data() {
    return {
      currentPage: 1,
      toolkitSearch: "",
      category: "",
      emotion: "",
      categoryOptions: [],
      firstLetter: null,
      letters: [{ letter: "all", flag: true }],
      recommendedToolkit: false,
      questionnaire: null,
    };
  },

  computed: {
    show() {
      return this.$store.getters["toolkitStoreModule/showOverlay"];
    },
    toolkits() {
      return this.$store.getters["toolkitStoreModule/toolkitList"];
    },
    rows() {
      return this.$store.getters["toolkitStoreModule/toolkitListTotal"];
    },
    upgradeOverlay() {
      if(this.$store.getters["app/fullPackagePrice"] != 0) {
        return false
      } else {
        return true
      }
    },
  },

  methods: {
    getAllToolkitCategories() {
      this.$http
        .get("/api/auth/toolkit-categories")
        .then((res) => {
          if(res != undefined) {
            for (let i = 0; i < res.data.length; i++) {
              // Push categires direct to categoryOptions because we need "all" value
              this.categoryOptions.push({
                value: res.data[i].id,
                text: res.data[i].name,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    getLetters() {
      return new Promise((resolve, reject) => {
        // Get all letters
        this.$http
          .get("/api/auth/toolkits/first-letters")
          .then((res) => {
            if(res != undefined) {
              this.letters = res.data;
              this.letters.splice(0, 0, { letter: "all", flag: true });

              for (let i = 0; i < this.letters.length; i++) {
                if (this.letters[i].flag == true) {
                  this.firstLetter = this.letters[i].letter;
                  break;
                }
              }

              resolve();
            }
          })
          .catch((error) => {
            console.log(error);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      });
    },

    setLetter(letter) {
      this.firstLetter = letter.letter;
      // Reset search term
      this.toolkitSearch = "";

      this.getToolkitList();
    },

    getToolkitList() {
      this.$store.dispatch('toolkitStoreModule/showOverlay', true)

      this.$store.dispatch(
        "toolkitStoreModule/getToolkitList",
        { letter: this.firstLetter, category: this.category, search: this.toolkitSearch, page: this.currentPage, recommended: this.recommendedToolkit, questionnaire: this.questionnaire, emotion: this.emotion }
      );
    },

    filterToolkits() {
      this.getToolkitList();
    },

    tellMeMore() {
      this.$router.push("/change-subscription-plan");      
    },

    areToolkitsFound(toolkits) {
      for(let i = 0; i < toolkits.length; i++) {
        if(toolkits[i].toolkits.length > 0) {
          return true;
        }
      }
      return false;
    }

  },

  mounted() {
    
    // Get full package price
    this.$store.dispatch('app/getFullPackagePrice')

    this.getLetters();

    // If clicked on view all (ToolkitList)
    if(this.$route.params.category) {
      this.category = this.$route.params.category
    }

    if(this.$route.query.emotion) {
      this.emotion = this.$route.query.emotion
    }
    
    // If clicked on view all (RecommendedToolkits)
    if(this.$route.params.recommended) {
      this.recommendedToolkit = this.$route.params.recommended
    }

    // If clicked on view recommended toolkits for emotional results on the my hub page
    if(this.$route.params.questionnaire) {
      this.questionnaire = this.$route.params.questionnaire;
    }
    
    this.getToolkitList();

    // Get all toolkit categories
    this.getAllToolkitCategories();

    EventBus.$on('resetToolkitList', () => {
      this.getLetters();
      this.getToolkitList();
      // Get all toolkit categories
      this.getAllToolkitCategories();
    });

  },
  watch: {
    currentPage(val) {
      this.getToolkitList();
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.letterFilter {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.letterFilter span {
  color: #bbb;
}

.text-underline {
  text-decoration: underline;
}

.green-text {
  color: #59949c !important;
  font-weight: bold;
  cursor: pointer;
}

.w-500 {
  max-width: 500px;
}

.toolkit-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding-bottom: 2rem;
}

.toolkit-flex-container {
  display: flex;
  overflow-x: auto;
}

.one-item {
  grid-template-columns: repeat(auto-fit, minmax(25rem, 50%));
}

@media (min-width: 1921px) {
  .toolkit-grid-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
  
@media (max-width: 784px) {
  .toolkit-grid-container {
    grid-template-columns: 1fr;
  }
}
// slick end

@media (max-width: 430px) {
  .toolkit-grid-container,
  .one-item {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  }

  .letterFilter {
    display: block;
    text-align: center;
  }
}

</style>
